@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

@media only screen and (min-width: 320px) {

    h4 {
        font-weight: 400 !important;
        font-size: calc(10px + 1.2vw) !important;
    }
    
    h3 {
        font-weight: 500 !important;
        font-size: calc(20px + 1.2vw) !important;
    }

    hr {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .header-container {
        padding-left: 0;
        padding-right: 0;
    }

    label {
        font-size: 14px;
    }

    label.semi-bold {
        font-size: 12px;
    }

    .row.mt-5 {
        margin-top: 0 !important;
    }

    div.col-sm-12.ml-5 {
        margin-left: 0 !important;
        margin-top: 32px !important;
    }

    div.col-sm-6.pl-5 {
        padding-left: 15px !important;
    }

    div.col.pl-5 {
        padding-left: 15px !important;
    }

    .yesno {
        width: 8rem;
    }

    .customer-support-mobile {
        position: absolute !important;
    }

    .customer-support {
        position: absolute !important;
    }

    .breadcrumb-item + .breadcrumb-item {
        padding-left: 0.2rem;
    }

    .breadcrumb-item + .breadcrumb-item::before {
        padding-right: 0.2rem;
    }
}

@media only screen and (min-width: 700px) {

    h4 {
        font-weight: 400 !important;
        font-size: calc(10px + 1.2vw) !important;
    }

    hr {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .header-container {
        padding-left: 0;
        padding-right: 0;
    }

    label {
        font-size: 14px;
    }

    label.semi-bold {
        font-size: 12px;
    }

    .row.mt-5 {
        margin-top: 0 !important;
    }

    div.col-sm-12.ml-5 {
        margin-left: 0 !important;
        margin-top: 32px !important;
    }

    div.col-sm-6.pl-5 {
        padding-left: 15px !important;
    }

    div.col.pl-5 {
        padding-left: 15px !important;
    }

    .yesno {
        width: 8rem;
    }

    .customer-support-mobile {
        position: fixed !important;
    }

    .customer-support {
        position: fixed !important;
    }

    .breadcrumb-item + .breadcrumb-item {
        padding-left: 0.5rem;
    }

    .breadcrumb-item + .breadcrumb-item::before {
        padding-right: 0.5rem;
    }
}

@media screen and (min-width: 960px) {
    html {
        margin-left: calc(100vw - 100%);
        margin-right: 0;
    }

    .row.mt-5 {
        margin-top: 3rem !important;
    }

    .mobile-break {
        display: none;
    }

    div.col.ml-5 {
        margin-left: 3rem !important;
    }

    div.col-sm-6.pl-5 {
        padding-left: 3rem !important;
    }

    div.col.pl-5 {
        padding-left: 3rem !important;
    }

    label {
        font-size: 1rem;
    }

    label.semi-bold {
        font-size: 1rem;
    }

    .print-footer {
        display: none;
    }
}

@media print {
    .print {
        display: block !important;
    }

    .no-print {
        display: none !important;
    }

    .break-before {
        break-before: page !important;
        page-break-before: always !important;
    }
    
    .break-after {
        break-after: page;
        page-break-after: always;
    }

    .print-footer {
        position: fixed;
        bottom: 5px;
        left: 10px;
    }
}

html {
    margin-left: 0;
    font-size: 1rem;
    /* When the font-size is set here, rem units are calculated from it  */
    overflow-y: scroll;
    overflow-x: hidden;
}

header {
    background-color: white;
}

#whoIsEnteringApplicationSection {
    margin-top: 1em;
}


/* Bootstrap style overwrites */
.btn-secondary {
    background-color: #e9ecef;
    color: #212529;
    border-color: #adb5bd;
}

.btn-secondary.active {
    background-color: #ca2c31 !important;
}

.btn-secondary:active {
    background-color: #6c757d !important;
}

.btn-secondary:hover {
    background-color: #6c757d;
}

.card-header {
    background-color: #444444;
    color: white;
}

.card-disabled {
    background-color: darkgray;
}


/* BCBS styles */
body {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    background-color: #f1f1f1;
}

label {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0.2rem;
}

h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: calc(1.3rem + 1vw);
    color: #444444;
}

h4 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: calc(1rem + 1vw);
}

p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 500;
}

.no-wrap {
    white-space: nowrap;
}

.question {
    margin-top: 1rem !important;
}

.screening-question {
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    color: #4c4c4c;
}

/* BCBS styles end */

a {
    color: #ca2c31;
    text-decoration: underline;
}

th {
    font-weight: 500;
}

code {
    color: #E01A76;
}

sup {
    color: red;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.logo img {
    height: 96px;
}

.semi-bold {
    font-weight: 500;
}

.required {
    color: red;
    padding-right: 0.2rem;
}

.hidden {
    display: none;
}

.brand-color-red {
    color: #ca2c31;
}

.brand-color-darkgray {
    color: #4c4c4c;
}

.brand-color-lightgray {
    color: #696969;
}

.brand-color-lighter-gray {
    color: #f1f1f1;
}

.error {
    color: red;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

/* Footer */

footer {
    clear: both;
    position: relative;
}

#pe-footer {
    font-size: 1.1rem;
    font-weight: 400;
    color: lightgray;
    background: #373737;
    padding: 24px 0 50px 0;
}

.footer-links {
    background-color: #444444;
    margin-left: 0;
    margin-right: 0;
    padding: 10px;
}

.footer-links a {
    color: white !important;
    font-size: min(calc(0.3rem + 1vw), 1rem);
}


.footer-divider {
    padding-left: calc(1vw);
    padding-right: calc(1vw);
    color: white;
}

#previousBtn {
    font-weight: 500;
    background-color: gainsboro;
}

#previousBtn:hover {
    background-color: #ca2c31;
    text-decoration: none;
    color: white;
}

.breadcrumb-divider-arrow {
    --bs-breadcrumb-divider: '>';
}

.breadcrumb {
    background-color: transparent;
    margin: 0;
    font-size: calc(8px + 1vw);
}

.breadcrumb-item.active {
    font-weight: bold;
    color: #cf2a27;
}

.bill-select {
    padding-left: 20px;
}

.medicare-card {
    border: 1px solid #b3b4b8;
    max-width: 100%;
    height: auto;
}

.medicare-link {
    color: rgb(27, 110, 194);
    text-decoration: none;
}
.medicare-link:hover {
    color: rgb(27, 110, 194);
    text-decoration: none;
}

.screen-card-question {
    margin-right: 4.5rem;
}

/* icons and tooltip from accuweather */
.icon-tooltip {
    border: 1px solid rgb(208, 208, 208);
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: flex;
    height: 20px;
    justify-content: center;
    width: 20px;
}

.icon-tooltip.is-active {
    background-color: #f05514;
    border: 1px solid #f05514;
    color: #fff;
}

.icon-tooltip:before {
    content: "i";
    font-size: 1rem;
    line-height: 20px;
}

.tooltip-overlay {
    background: #fff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    display: none;
    padding: 12px 12px 24px 1rem;
    position: absolute;
    right: -12px;
    top: -12px;
    width: calc(100vw - 24px);
    z-index: 6;
}

.tooltip-overlay .tooltip-header {
    display: flex;
    margin-bottom: 8px;
}

.tooltip-overlay .tooltip-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43;
    padding-right: 32px;
    text-align: left;
    text-transform: none;
}

.tooltip-button {
    border: 0;
    background: inherit;
    font-size: 0;
    color: white;
}

.icon-color {
    color: #1d3d70;
}

.popover-header {
    background-color: #ca2c31;
    color: white;
}


/* Modals */
.modal-header {
    background-color: #007bff2b;
}

.customer-support {
    position: fixed;
    top: 80px;
    right: 25px;
    z-index: 999;
}

.customer-support-mobile {
    position: absolute;
    top: 0;
    right: 14px;
    z-index: 999;
}

.customer-support-tooltip {
    background-color: #ca2c31;
    border: 1px solid #ca2c31;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    width: 50px;
    font-size: 0;
}

.customer-support-tooltip:before {
    content: "?";
    font-size: 24px;
    line-height: 50px;
    font-weight: 600 !important;
}

.heading-medium {
    font-size: larger;
    font-weight: 700;
}

.text-strong {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 600;
}

#warning-icon {
    height: 40px;
    width: 40px;
    color: #ca2c31;
}

/* input range style start */
input[type=range] {
    height: 20px;
    -webkit-appearance: none;
    margin: 10px 0;
    background-color: unset;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0 0 0 #000000;
    background: #2497E3;
    border-radius: 1px;
    border: 0 solid #000000;
}

input[type=range]::-webkit-slider-thumb {
    box-shadow: 0 0 0 #000000;
    border: 1px solid #2497E3;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #A1D0FF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #2497E3;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0 0 0 #000000;
    background: #2497E3;
    border-radius: 1px;
    border: 0 solid #000000;
}

input[type=range]::-moz-range-thumb {
    box-shadow: 0 0 0 #000000;
    border: 1px solid #2497E3;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #A1D0FF;
    cursor: pointer;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: #2497E3;
    border: 0 solid #000000;
    border-radius: 2px;
    box-shadow: 0 0 0 #000000;
}

input[type=range]::-ms-fill-upper {
    background: #2497E3;
    border: 0 solid #000000;
    border-radius: 2px;
    box-shadow: 0 0 0 #000000;
}

input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0 0 0 #000000;
    border: 1px solid #2497E3;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #A1D0FF;
    cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
    background: #2497E3;
}

input[type=range]:focus::-ms-fill-upper {
    background: #2497E3;
}

/* input range style end  */

.siteDown {
    display: flex;
    /*display: -webkit-flex;*/
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.siteDown-item {
    flex: 1;
}

.siteDown::before {
    content: url("./assets/wrench-adjustable-circle.png");
}

input[type=radio] {
    transform: scale(1.5);
    margin-top: 0.2rem;
}

input[type=checkbox] {
    transform: scale(1.5);
    margin-top: 0.2rem;
}

/* Remove the placeholder from date fields */
input[type=date]::-webkit-datetime-edit-year-field:not([aria-valuenow]),
input[type=date]::-webkit-datetime-edit-month-field:not([aria-valuenow]),
input[type=date]::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
}

.check-list label {
    margin-left: 16px;
    font-size: 14px;
}

.check-list label input{
}

.check-list .check-list-header {
    margin-bottom: 0.5rem;
    border-bottom: 1px solid lightgray;
}

.check-list .check-list-body {
}

.check-list .check-list-item {
    margin-left: 18px;
}

.check-list .check-list-item input {
    margin-left: -24px;
    margin-right: 6px;
}

.check-list .check-list-column-1 {
    column-count: 1;
}

.check-list .check-list-column-2 {
    column-count: 2;
}

.medicare-icon-tooltip {
    border: 1px solid rgb(219, 112, 147);
    border-radius: 50%;
    color: palevioletred;
    cursor: pointer;
    display: flex;
    height: 20px;
    justify-content: center;
    width: 20px;
}

.button-align-bottom {
    display: flex;
    align-items: end;
    justify-content: start;
}


/*************************/
.link-button {
    background: none!important;
    border: none;
    padding: 0!important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: royalblue;
    text-decoration: none;
    cursor: pointer;
    margin: 24px 0 0 0;
}
.link-button:hover {
    text-decoration: underline;
}
.link-button:active {
    transform: scale(0.9);
    text-decoration: none;
}