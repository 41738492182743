
fieldset legend {
    font-size: 1.3rem;
}

fieldset.header {
    margin-top: 1rem !important;
}

.facilityInfo {
    border: solid 1px gainsboro;
    padding: 1rem;
    margin: 0.2rem;
}

.npn-wide {
    width: 10rem !important;
}

.agent-wide {
    width: 15rem !important;
}
